import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DataValidation.css";
import { FaFileAlt } from "react-icons/fa";

export default function DataValidation() {
  const intakeLogId = localStorage.getItem("intake_log_id");
  const [residents, setResidents] = useState([]);
  const [selectedResident, setSelectedResident] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentText, setDocumentText] = useState("");

  const [currentProcessingId, setCurrentProcessingId] = useState(null);
  const [processedCount, setProcessedCount] = useState(0);
  const [processingComplete, setProcessingComplete] = useState(false);

  useEffect(() => {
    if (intakeLogId) {
      fetchResidentFolders();
    }
  }, [intakeLogId]);

  const fetchResidentFolders = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/intake/residents/${intakeLogId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setResidents(response.data);
    } catch (error) {
      console.error("Error fetching residents:", error);
    }
  };

  const handleResidentSelect = async (resident) => {
    setSelectedResident(resident);
    setDocuments([]);
    setDocumentText("");

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/intake/documents/${resident.intake_log_detail_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleDocumentSelect = async (document) => {
    setSelectedDocument(document);
    setDocumentText("");

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/intake/document-text/${document.intake_document_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDocumentText(response.data.extracted_text);
    } catch (error) {
      console.error("Error fetching document text:", error);
    }
  };

  const handleRunLLM = async () => {
    if (!intakeLogId || residents.length === 0) return;

    const token = localStorage.getItem("authToken");
    setProcessedCount(0);
    setProcessingComplete(false);

    for (const resident of residents) {
      setCurrentProcessingId(resident.intake_log_detail_id);

      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/intake/process_llm/resident/${resident.intake_log_detail_id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setResidents((prev) =>
          prev.map((r) =>
            r.intake_log_detail_id === resident.intake_log_detail_id
              ? { ...r, status: "COMPLETED" }
              : r
          )
        );
      } catch (error) {
        console.error("Error processing resident:", resident.detail_source_path, error);
        setResidents((prev) =>
          prev.map((r) =>
            r.intake_log_detail_id === resident.intake_log_detail_id
              ? { ...r, status: "FAILED" }
              : r
          )
        );
      }

      setProcessedCount((prev) => prev + 1);
    }

    setCurrentProcessingId(null);
    setProcessingComplete(true);
  };

  return (
    <div className="data-validation-wrapper">
      <div className="header-row">
        <button className="llm-button" onClick={handleRunLLM}>
          Process Folder
        </button>
      </div>
      <div className="data-validation-container">
        {/* 🔹 Left Sidebar - Resident Folders */}
        <div className="resident-list">
          <h3>Resident Folders</h3>

          {/* 🔄 Global Progress Bar */}
          {processedCount > 0 && (
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `${(processedCount / residents.length) * 100}%` }}
              />
              <div className="progress-text">
                {processedCount} of {residents.length} processed
              </div>
            </div>
          )}

          {/* ✅ Completion Message */}
          {processingComplete && (
            <div className="completion-message">🎉 Processing complete!</div>
          )}

          <ul>
            {residents.map((resident) => (
              <li
                key={resident.intake_log_detail_id}
                className={`resident-folder ${selectedResident === resident ? "selected" : ""}`}
                onClick={() => handleResidentSelect(resident)}
              >
                <div className="folder-row">
                  <span className="folder-name">{resident.detail_source_path}</span>
                  {currentProcessingId === resident.intake_log_detail_id ? (
                    <span className="spinner"></span>
                  ) : resident.status ? (
                    <span className={`status-tag status-${resident.status.toLowerCase()}`}>
                      {resident.status}
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* 🔹 Right Section */}
        <div className="right-section">
          <div className="document-list">
            <h3>Documents</h3>
            <div className="document-cards">
              {documents.map((document) => (
                <div
                  key={document.intake_document_id}
                  className={`document-card ${
                    selectedDocument === document ? "selected" : ""
                  }`}
                  onClick={() => handleDocumentSelect(document)}
                >
                  <FaFileAlt className="document-icon" />
                  <span className="document-name">{document.file_name}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="document-text">
            <h3>Extracted Text</h3>
            <textarea
              readOnly
              value={documentText || "Select a document to view text..."}
            />
          </div>
        </div>



      </div>
    </div>
  );
}