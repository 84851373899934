import React from "react";
import { Link } from "react-router-dom";
import "./Menu.css";

export default function Menu({ menu, isMenuOpen, toggleMenu }) {
  return (
    <nav className={`menu-slide ${isMenuOpen ? "open" : ""}`}>
      <ul>
        {menu.map((menuItem) => (
          <li key={menuItem.path} onClick={toggleMenu}>
            <Link to={menuItem.path}>{menuItem.name}</Link>
            {menuItem.submenus && (
              <ul className="submenu">
                {menuItem.submenus.map((submenu) => (
                  <li key={submenu.path} onClick={toggleMenu}>
                    <Link to={submenu.path}>{submenu.name}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}