import "./LoginPage.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoginStats from './LoginStats';

export default function LoginPage({ onLogin }) {
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, credentials);
      const { token } = response.data;

      if (!token) {
        alert("Login failed: No token received.");
        return;
      }

      localStorage.setItem("authToken", token);

      const routesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/routes/user_routes`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!routesResponse.data || !routesResponse.data.routes) {
        alert("Login failed: No user routes found.");
        return;
      }

      localStorage.setItem("userRoutes", JSON.stringify(routesResponse.data.routes));

      if (typeof onLogin === "function") {
        onLogin(token, routesResponse.data.routes);
      }

      navigate("/dashboard");
    } catch (error) {
      alert("Invalid credentials or API failure.");
    }
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-main-content">
        <div className="branding">
          <img src="/Data For Care.png" alt="Data For Care" className="company-logo" />
        </div>

        {/* Login box appears right below logo */}
        <div className="login-box-wrapper">
          <div className="login-box">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" name="username" value={credentials.username} onChange={handleChange} placeholder="Username" required />
              <input type="password" name="password" value={credentials.password} onChange={handleChange} placeholder="Password" required />
              <button type="submit">Login</button>
            </form>
          </div>
        </div>

        <LoginStats />

        <div className="product-section">
          <h2>Data For Care - Solutions</h2>
          <div className="product-grid">
            <a className="product-block" href="https://www.dataforcare.com" target="_blank" rel="noopener noreferrer">
              <img src="/Staffing.png" alt="Acuity Based Staffing" />
              <h3>Acuity Based Staffing</h3>
              <p>Ensure optimal staff allocation based on resident needs and real-time data insights.</p>
            </a>
            <a className="product-block" href="https://www.dataforcare.com" target="_blank" rel="noopener noreferrer">
              <img src="/Match.png" alt="Resident and Community Matching" />
              <h3>Resident Matching with Community</h3>
              <p>Intelligently match residents with communities that align with their preferences and care needs.</p>
            </a>
            <a className="product-block" href="https://www.dataforcare.com" target="_blank" rel="noopener noreferrer">
              <img src="/Plan.png" alt="Personalized Service Planning" />
              <h3>Personalized Service Plans</h3>
              <p>Customize care plans with data-driven insights to enhance resident satisfaction and outcomes.</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}