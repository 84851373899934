import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { FaClock, FaUpload, FaCloudUploadAlt, FaExchangeAlt } from "react-icons/fa";
import "./UploadDataStyled.css";

export default function UploadData() {
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const currentTab = pathParts[pathParts.length - 1];

    if (["current", "local", "s3", "api"].includes(currentTab)) {
      setActiveTab(currentTab);
    } else {
      setActiveTab("current");
      navigate("/dashboard/resident/upload/current");
    }
  }, [location.pathname, navigate]);

  const handleTabClick = (tab) => {
    navigate(`/dashboard/resident/upload/${tab}`);
  };

  return (
    <div className="upload-data-wrapper">
      <h2 className="upload-title">Resident Data Upload</h2>
      <p className="upload-subtitle">
        Choose a method to upload and analyze your resident data.
      </p>

      <div className="upload-tabs">
        <button
          className={`upload-tab ${activeTab === "current" ? "active" : ""}`}
          onClick={() => handleTabClick("current")}
        >
          <FaClock className="tab-icon" /> Current Uploads
        </button>
        <button
          className={`upload-tab ${activeTab === "local" ? "active" : ""}`}
          onClick={() => handleTabClick("local")}
        >
          <FaUpload className="tab-icon" /> Upload from Local
        </button>
        <button
          className={`upload-tab ${activeTab === "s3" ? "active" : ""}`}
          onClick={() => handleTabClick("s3")}
        >
          <FaCloudUploadAlt className="tab-icon" /> Upload to S3
        </button>
        <button
          className={`upload-tab ${activeTab === "api" ? "active" : ""}`}
          onClick={() => handleTabClick("api")}
        >
          <FaExchangeAlt className="tab-icon" /> API Upload
        </button>
      </div>

      <div className="upload-tab-content">
        <Outlet />
      </div>
    </div>
  );
}
