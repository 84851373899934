import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCheckCircle,
  faChartLine,
  faClipboardList,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import "./ResidentOverview.css";

export default function ResidentOverview() {
  const location = useLocation();

  // Define the step sequence
  const steps = [
    { path: "upload", label: "Upload Data", icon: faUpload },
    { path: "validate", label: "Data Processing", icon: faCheckCircle },
    { path: "risk-scoring", label: "Risk & Acuity Scoring", icon: faChartLine },
    { path: "summary", label: "Summarization", icon: faClipboardList },
    { path: "staffing", label: "Staffing Model", icon: faUsers }
  ];

  // Determine which step is active
  const activeIndex = steps.findIndex(step => location.pathname.includes(step.path));

  return (
    <div className="resident-overview-container">
      <h1>Generate Custom Staffing Model</h1>

      {/* Step Navigation with Progress Line */}
      <div className="step-navigation">
        <div className="progress-line">
          <div
            className="progress-fill"
            style={{ width: `${(activeIndex / (steps.length - 1)) * 100}%` }}
          ></div>
        </div>

        {steps.map((step, index) => (
          <Link
            key={step.path}
            to={step.path}
            className={`step ${index <= activeIndex ? "active" : ""}`}
          >
            <FontAwesomeIcon icon={step.icon} className="step-icon" />
            <span>{step.label}</span>
          </Link>
        ))}
      </div>

      {/* Page Content */}
      <div className="resident-content">
        <Outlet />
      </div>
    </div>
  );
}