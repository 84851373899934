import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import axios from "axios";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menu, setMenu] = useState([]);
  const [customerName, setCustomerName] = useState("Unknown Facility");
  const [customerId, setCustomerId] = useState(null); // ✅ Track Customer ID

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const storedCustomerId = localStorage.getItem("customerId"); // ✅ Retrieve from localStorage
    const storedCustomerName = localStorage.getItem("customerName") || "Unknown Facility";

    if (token) {
      setIsLoggedIn(true);
      setCustomerId(storedCustomerId);
      setCustomerName(storedCustomerName);

      // ✅ Fetch user details (including customer name & ID)
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/auth/user-info`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const name = response.data.customer_name || "Unknown Facility";
          const id = response.data.customer_id || null;

          setCustomerName(name);
          setCustomerId(id);

          localStorage.setItem("customerName", name);
          localStorage.setItem("customerId", id);
        })
        .catch((err) => {
          console.error("Error fetching user info:", err);
        });

      // ✅ Fetch user menu routes
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/routes/user_routes`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setMenu(response.data.routes || []);
          localStorage.setItem("userRoutes", JSON.stringify(response.data.routes));
        })
        .catch((err) => {
          console.error("Error fetching menu:", err);
          setMenu([]);
        });
    }
  }, []);

  const handleLogin = async (token, userRoutes) => {
    console.log("Login successful, saving token...");
    localStorage.setItem("authToken", token);
    localStorage.setItem("userRoutes", JSON.stringify(userRoutes));

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user-info`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const customerName = response.data.customer_name || "Unknown Facility";
      const customerId = response.data.customer_id || null;

      console.log("Fetched Customer Info:", { customerName, customerId });

      localStorage.setItem("customerName", customerName);
      localStorage.setItem("customerId", customerId);

      setCustomerName(customerName);
      setCustomerId(customerId);
    } catch (error) {
      console.error("Error fetching user info:", error);
      setCustomerName("Unknown Facility");
      setCustomerId(null);
    }

    setIsLoggedIn(true);
    setMenu(userRoutes);
  };

  const handleLogout = () => {
    console.log("Logging out...");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRoutes");
    localStorage.removeItem("customerName");
    localStorage.removeItem("customerId");

    setIsLoggedIn(false);
    setMenu([]);
    setCustomerName("Unknown Facility");
    setCustomerId(null);
  };

  return (
    <Router>
      <AppRoutes
        isLoggedIn={isLoggedIn}
        menu={menu}
        customerName={customerName}
        customerId={customerId} // ✅ Pass to routes
        onLogin={handleLogin}
        onLogout={handleLogout}
      />
    </Router>
  );
}

export default App;