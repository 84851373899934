import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer
} from "recharts";
import "./StaffingModel.css";

export default function StaffingModel() {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [staffingData, setStaffingData] = useState([]);
  const [groupByUnit, setGroupByUnit] = useState(false);
  const [loading, setLoading] = useState(false);

  const intakeLogId = localStorage.getItem("intake_log_id");
  const token = localStorage.getItem("authToken");

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/templates`, headers)
      .then((res) => setTemplates(res.data))
      .catch((err) => console.error("Error fetching templates:", err));
  }, []);

  const fetchStaffingModel = () => {
    if (!selectedTemplateId || !intakeLogId) return;
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/staffing/model`, {
        template_id: selectedTemplateId,
        intake_log_id: intakeLogId,
        group_by_unit: groupByUnit,
      }, headers)
      .then((res) => {
        const enriched = res.data.map((row) => ({
          ...row,
          staff_per_day: Math.max(1, Math.ceil(row.weighted_risk_score / 500)),
        }));
        setStaffingData(enriched);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching staffing model:", err);
        setLoading(false);
      });
  };

  const renderChart = (data, title, metric, color) => (
    <div className="chart-wrapper">
      <h3>{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barCategoryGap="20%"
          barGap={8}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="role_name" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar dataKey={metric} name={metric === "staff_per_day" ? "Staff Needed / Day" : "Weighted Risk Score"} fill={color} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  const groupedByUnit = staffingData.reduce((acc, row) => {
    const unit = row.location || "Unassigned";
    if (!acc[unit]) acc[unit] = [];
    acc[unit].push(row);
    return acc;
  }, {});

  const renderTable = (data) => (
    <table className="staffing-table">
      <thead>
        <tr>
          <th>Role</th>
          <th>Risk Count</th>
          <th>Weighted Risk Score</th>
          <th>Avg Responsibility</th>
          <th>Staff Needed / Day</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.staff_role_code + row.location}>
            <td>{row.role_name}</td>
            <td>{row.risk_count}</td>
            <td>{row.weighted_risk_score.toFixed(2)}</td>
            <td>{row.avg_responsibility_level.toFixed(2)}</td>
            <td>{row.staff_per_day}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="staffing-model-container">
      <h2>Staffing Model</h2>
      <div className="staffing-form">
        <label>Select Template:</label>
        <select
          value={selectedTemplateId}
          onChange={(e) => setSelectedTemplateId(e.target.value)}
        >
          <option value="">-- Select Template --</option>
          {templates.map((tpl) => (
            <option key={tpl.template_id} value={tpl.template_id}>
              {tpl.template_name}
            </option>
          ))}
        </select>

        <label>
          <input
            type="checkbox"
            checked={groupByUnit}
            onChange={(e) => setGroupByUnit(e.target.checked)}
          />
          Group by Unit
        </label>

        <button onClick={fetchStaffingModel} disabled={!selectedTemplateId || loading}>
          {loading ? "Loading..." : "Generate Model"}
        </button>
      </div>

      {/* 📊 Chart Section */}
      {groupByUnit ? (
        Object.entries(groupedByUnit).map(([unit, data]) => (
          <div key={unit}>
            {renderChart(data, `Unit: ${unit} - Weighted Risk Score`, "weighted_risk_score", "#f44336")}
            {renderChart(data, `Unit: ${unit} - Staff Needed / Day`, "staff_per_day", "#4caf50")}
            {renderTable(data)}
          </div>
        ))
      ) : (
        staffingData.length > 0 && (
          <>
            {renderChart(staffingData, "Facility-Wide Weighted Risk Score", "weighted_risk_score", "#f44336")}
            {renderChart(staffingData, "Facility-Wide Staff Needed / Day", "staff_per_day", "#4caf50")}
            {renderTable(staffingData)}
          </>
        )
      )}
    </div>
  );
}