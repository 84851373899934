import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Menu from "../../Menu/Menu";
import "./Dashboard.css";

export default function Dashboard({ menu, customerName, onLogout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="dashboard-container">
      {/* HEADER (Always at the Top) */}
      <header className="dashboard-header">
        <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? "✖" : "☰"}
        </button> 
        <h1>{customerName || "Unknown Facility"}</h1> {/* Show Facility Name */}
        <div className="header-right">
          <button className="logout-button" onClick={onLogout}>Logout</button>
        </div>
      </header>

      {/* Menu Component */}
      <Menu menu={menu} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

      {/* Main Content */}
      <main className="dashboard-main">
        <Outlet />
      </main>
    </div>
  );
}