import React, { useEffect, useState } from "react";
import axios from "axios";
import "./StaffingTemplate.css";
import {
  FaHeartbeat,
  FaUserNurse,
  FaBrain,
  FaWheelchair,
  FaStethoscope,
} from "react-icons/fa";

const RESPONSIBILITY_LEVELS = [
  { value: 0, label: "None" },
  { value: 1, label: "Manager" },
  { value: 2, label: "Guide" },
  { value: 3, label: "Sub Responsibility" },
  { value: 4, label: "High" },
  { value: 5, label: "Core/Primary" },
];

const iconMap = {
  FALL_RISK: <FaWheelchair className="risk-icon" />,
  MEMORY_CARE: <FaBrain className="risk-icon" />,
  ADL_SUPPORT: <FaUserNurse className="risk-icon" />,
  HOSPITAL_RISK: <FaStethoscope className="risk-icon" />,
  ACUITY_SCORE: <FaHeartbeat className="risk-icon" />,
};

export default function StaffingTemplate() {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [matrix, setMatrix] = useState([]);
  const [roles, setRoles] = useState([]);
  const [riskTypes, setRiskTypes] = useState([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [copyFromTemplateId, setCopyFromTemplateId] = useState("");

  const token = localStorage.getItem("authToken");

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/templates`, headers).then((res) => setTemplates(res.data));
    axios.get(`${process.env.REACT_APP_API_URL}/api/staff_roles`, headers).then((res) => setRoles(res.data));
    axios.get(`${process.env.REACT_APP_API_URL}/api/risk_types`, headers).then((res) => setRiskTypes(res.data));
  }, []);

  useEffect(() => {
    if (!selectedTemplateId) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/template_matrix/${selectedTemplateId}`, headers)
      .then((res) => setMatrix(res.data));
  }, [selectedTemplateId]);

  const handleLevelChange = (riskCode, roleCode, type, value) => {
    setMatrix((prev) => {
      const updated = [...prev];
      const item = updated.find(
        (m) => m.risk_type_code === riskCode && m.staff_role_code === roleCode
      );
      if (item) {
        item[type] = parseInt(value);
      } else {
        updated.push({
          risk_type_code: riskCode,
          staff_role_code: roleCode,
          min_responsibility_level:
            type === "min_responsibility_level" ? parseInt(value) : 0,
          max_responsibility_level:
            type === "max_responsibility_level" ? parseInt(value) : 0,
        });
      }
      return updated;
    });
  };

  const saveMatrix = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/template_matrix/save/${selectedTemplateId}`,
        matrix,
        headers
      )
      .then(() => {
        alert("Saved successfully!");
      });
  };

  const createTemplate = async () => {
    if (!newTemplateName.trim()) return alert("Please enter a template name.");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/templates/create`,
        {
          template_name: newTemplateName,
          copy_from_template_id: copyFromTemplateId || null,
        },
        headers
      );
      const newId = response.data.template_id;
      const updated = await axios.get(`${process.env.REACT_APP_API_URL}/api/templates`, headers);
      setTemplates(updated.data);
      setSelectedTemplateId(newId);
      setShowCreateModal(false);
      setNewTemplateName("");
      setCopyFromTemplateId("");
    } catch (err) {
      console.error("Error creating template", err);
      alert("Failed to create template");
    }
  };

  return (
    <div className="staffing-template-wrapper">
      <div className="staffing-template-header">
        <h2>Staffing Role Responsibility Matrix</h2>
        <div className="template-actions">
          <select
            className="template-select"
            value={selectedTemplateId}
            onChange={(e) => setSelectedTemplateId(e.target.value)}
          >
            <option value="">Select Template</option>
            {templates.map((tpl) => (
              <option key={tpl.template_id} value={tpl.template_id}>
                {tpl.template_name}
              </option>
            ))}
          </select>
          <button onClick={() => setShowCreateModal(true)}>➕ New Template</button>
        </div>
      </div>

      {selectedTemplateId && (
        <>
          <table className="matrix-table">
            <thead>
              <tr>
                <th className="risk-header">Risk Type</th>
                {roles.map((role) => (
                  <th key={role.staff_role_code}>{role.role_name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {riskTypes.map((risk) => (
                <tr key={risk.risk_type_code}>
                  <td className="risk-header">
                    <span className="risk-label">
                      {iconMap[risk.risk_type_code] || (
                        <FaStethoscope className="risk-icon" />
                      )}
                      {risk.risk_type_name}
                    </span>
                  </td>
                  {roles.map((role) => {
                    const match =
                      matrix.find(
                        (m) =>
                          m.risk_type_code === risk.risk_type_code &&
                          m.staff_role_code === role.staff_role_code
                      ) || {};
                    return (
                      <td key={role.staff_role_code} className="select-cell">
                        <select
                          value={match.min_responsibility_level ?? 0}
                          onChange={(e) =>
                            handleLevelChange(
                              risk.risk_type_code,
                              role.staff_role_code,
                              "min_responsibility_level",
                              e.target.value
                            )
                          }
                        >
                          {RESPONSIBILITY_LEVELS.map((lvl) => (
                            <option key={lvl.value} value={lvl.value}>
                              {lvl.label}
                            </option>
                          ))}
                        </select>
                        <select
                          value={match.max_responsibility_level ?? 0}
                          onChange={(e) =>
                            handleLevelChange(
                              risk.risk_type_code,
                              role.staff_role_code,
                              "max_responsibility_level",
                              e.target.value
                            )
                          }
                        >
                          {RESPONSIBILITY_LEVELS.map((lvl) => (
                            <option key={lvl.value} value={lvl.value}>
                              {lvl.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>

          <button className="save-button" onClick={saveMatrix}>
            Save Changes
          </button>
        </>
      )}

      {showCreateModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Create New Template</h3>
            <input
              type="text"
              placeholder="Enter template name"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
            <select
              value={copyFromTemplateId}
              onChange={(e) => setCopyFromTemplateId(e.target.value)}
            >
              <option value="">(Optional) Copy from existing</option>
              {templates.map((tpl) => (
                <option key={tpl.template_id} value={tpl.template_id}>
                  {tpl.template_name}
                </option>
              ))}
            </select>
            <div className="modal-buttons">
              <button onClick={createTemplate}>Create</button>
              <button onClick={() => setShowCreateModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}