import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './LoginStats.css';

const seniorPopulationData = [
  { year: 2000, population: 35 },
  { year: 2010, population: 40 },
  { year: 2020, population: 55 },
  { year: 2025, population: 63 },
  { year: 2030, population: 72 }
];

const caregiverRatioData = [
  { year: "2000", ratio: 8 },
  { year: "2010", ratio: 7 },
  { year: "2020", ratio: 6 },
  { year: "2025", ratio: 5 },
  { year: "2030", ratio: 4 }
];

const staffingShortageData = [
  { year: 2000, shortage: 50000 },
  { year: 2010, shortage: 100000 },
  { year: 2020, shortage: 200000 },
  { year: 2025, shortage: 350000 },
  { year: 2030, shortage: 500000 }
];

export default function LoginStats() {
  return (
    <div className="login-stats">
      <div className="chart-block">
        <h4>US Senior Population Growth (65+)</h4>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={seniorPopulationData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="population" fill="#6a8caf" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="chart-block">
        <h4>Resident-to-Caregiver Ratio</h4>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={caregiverRatioData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="ratio" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="chart-block">
        <h4>Projected Staffing Shortage</h4>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={staffingShortageData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="shortage" fill="#ff7f50" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}