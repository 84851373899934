import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./RiskScoring.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

export default function RiskScoring() {
  const [riskData, setRiskData] = useState([]);
  const [riskTypes, setRiskTypes] = useState([]);
  const [groupedRiskTypes, setGroupedRiskTypes] = useState({});
  const [selectedResident, setSelectedResident] = useState(null);

  useEffect(() => {
    const intakeLogId = localStorage.getItem("intake_log_id");
    if (!intakeLogId) return;

    const token = localStorage.getItem("authToken");

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/intake/risk_scores/${intakeLogId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRiskData(response.data.residents || []);
        setRiskTypes(response.data.risk_types || []);
      })
      .catch((error) => {
        console.error("Error fetching risk scores:", error);
      });
  }, []);

  useEffect(() => {
    const grouped = {};
    riskTypes.forEach((rt) => {
      if (!grouped[rt.risk_category]) {
        grouped[rt.risk_category] = [];
      }
      grouped[rt.risk_category].push(rt);
    });
    setGroupedRiskTypes(grouped);
  }, [riskTypes]);

  const handleResidentClick = (resident) => {
    setSelectedResident(resident);
  };

  const getBarChartData = (resident) => {
    const labels = [];
    const values = [];
    const colors = [];

    riskTypes.forEach((risk) => {
      const value = resident[risk.risk_type_code] ?? 0;
      labels.push(risk.risk_type_name);
      values.push(value);
      colors.push(value > 6 ? "#d32f2f" : "#2196f3");
    });

    return {
      labels,
      datasets: [
        {
          label: "Risk Score",
          data: values,
          backgroundColor: colors,
        },
      ],
    };
  };

  const verticalChartOptions = {
    indexAxis: 'y', // This flips the bar chart to vertical layout
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => `Score: ${context.raw}`
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 10
      }
    }
  };

  const [collapsedUnits, setCollapsedUnits] = useState(new Set());

  const toggleUnitCollapse = (unitName) => {
    setCollapsedUnits((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(unitName)) {
        newSet.delete(unitName);
      } else {
        newSet.add(unitName);
      }
      return newSet;
    });
  };



  return (
    <div className="risk-scoring-container">
      <h2>Resident Risk and Acuity Scoring</h2>

      <table className="risk-summary-table">
        <thead>
        <tr>
        <th rowSpan={2}>Name</th>
        <th rowSpan={2}>Overall Score</th>
        {Object.entries(groupedRiskTypes).map(([category, risks], idx) => (
          <th key={category} colSpan={risks.length} className={`category-header category-${idx}`}>
            {category}
          </th>
        ))}
        </tr>
        <tr>
        {Object.entries(groupedRiskTypes).flatMap(([_, risks]) =>
          risks.map((risk) => (
            <th key={risk.risk_type_code}>{risk.risk_type_name}</th>
          ))
        )}
        </tr>
        </thead>

        <tbody>
  {Object.entries(
    riskData.reduce((grouped, resident) => {
      const location = resident.location || "Unassigned";
      if (!grouped[location]) grouped[location] = [];
      grouped[location].push(resident);
      return grouped;
    }, {})
  ).map(([location, residents]) => {
    const isCollapsed = collapsedUnits.has(location);
    return (
      <React.Fragment key={location}>
       <tr
          className="unit-header-row"
          onClick={() => toggleUnitCollapse(location)}
          style={{ cursor: "pointer" }}
        >
          <td colSpan={2 + riskTypes.length}>
            <div className="unit-header-content">
              <span className="unit-toggle">{isCollapsed ? "+" : "−"}</span>
              <span className="unit-label">Location: {location}</span>
            </div>
          </td>
        </tr>
        {!isCollapsed &&
          residents.map((resident) => (
            <tr
              key={resident.resident_id}
              onClick={() => handleResidentClick(resident)}
              style={{ cursor: "pointer" }}
            >
              <td>{resident.resident_name}</td>
              <td className={resident.ACUITY_SCORE > 6 ? "critical-score" : ""}>
                {resident.ACUITY_SCORE ?? 0}
              </td>
              {Object.entries(groupedRiskTypes).flatMap(([_, risks]) =>
                risks.map((risk) => {
                  const value = resident[risk.risk_type_code] ?? 0;
                  return (
                    <td
                      key={`${resident.resident_id}-${risk.risk_type_code}`}
                      className={value > 6 ? "critical-score" : ""}
                    >
                      {value}
                    </td>
                  );
                })
              )}
            </tr>
          ))}
      </React.Fragment>
    );
  })}
</tbody>

      </table>

      {selectedResident && (
        <div className="resident-modal-overlay" onClick={() => setSelectedResident(null)}>
          <div className="resident-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={() => setSelectedResident(null)}>✖</button>

            <div className="resident-info">
              <h3>Resident Details</h3>
              <p><strong>Name:</strong> {selectedResident.resident_name}</p>
              <p><strong>DOB:</strong> {selectedResident.date_of_birth || "N/A"}</p>
              <p><strong>Gender:</strong> {selectedResident.gender || "N/A"}</p>

              <div className="analysis-paragraph">
                Below is the resident's risk profile for various categories based on the most recent clinical documentation.
              </div>
            </div>

            <div className="chart-container">
              <Bar data={getBarChartData(selectedResident)} options={verticalChartOptions} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}