import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend
} from "recharts";
import "./Summarization.css";

export default function Summarization() {
  const [residents, setResidents] = useState([]);
  const [riskTypes, setRiskTypes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedRiskType, setSelectedRiskType] = useState("FALL_RISK");

  useEffect(() => {
    const intakeLogId = localStorage.getItem("intake_log_id");
    const token = localStorage.getItem("authToken");

    if (!intakeLogId || !token) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/intake/risk_scores/${intakeLogId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setResidents(res.data.residents || []);
        setRiskTypes(res.data.risk_types || []);
      })
      .catch((err) => console.error("Error loading summary data:", err));
  }, []);

  const filteredResidents = residents.filter((res) =>
    res.resident_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const totalResidents = filteredResidents.length;
  const averageAcuity = totalResidents
    ? (
        filteredResidents.reduce((sum, r) => sum + (r.ACUITY_SCORE ?? 0), 0) /
        totalResidents
      ).toFixed(2)
    : 0;

  const averageFallRisk = totalResidents
    ? (
        filteredResidents.reduce((sum, r) => sum + (r.FALL_RISK ?? 0), 0) /
        totalResidents
      ).toFixed(2)
    : 0;

  const top10ByField = (field) =>
    [...filteredResidents]
      .filter((r) => r[field] != null)
      .sort((a, b) => b[field] - a[field])
      .slice(0, 10)
      .map((r) => ({
        name: r.resident_name,
        score: r[field],
      }));

  const topAcuityData = top10ByField("ACUITY_SCORE");
  const topSelectedRiskData = top10ByField(selectedRiskType);

  const getChartData = () => {
    return filteredResidents.map((resident) => {
      const entry = { name: resident.resident_name };
      riskTypes.forEach((rt) => {
        entry[rt.risk_type_name] = resident[rt.risk_type_code] ?? 0;
      });
      return entry;
    });
  };

    // Helper: Aggregate by unit
  const unitSummary = Object.entries(
    filteredResidents.reduce((acc, r) => {
      const unit = r.location || "Unassigned";
      if (!acc[unit]) acc[unit] = { total: 0, highAcuity: 0, highFall: 0 };
      acc[unit].total += 1;
      if ((r.ACUITY_SCORE ?? 0) > 30) acc[unit].highAcuity += 1;
      if ((r.FALL_RISK ?? 0) > 2) acc[unit].highFall += 1;
      return acc;
    }, {})
  ).map(([unit, stats]) => ({
    unit,
    total: stats.total,
    highAcuity: stats.highAcuity,
    highFall: stats.highFall
  }));

  return (
    <div className="summarization-container">
      <h2>Resident Summarization & Reporting</h2>
      <p>Explore summarized resident risk profiles.</p>

      <div className="chart-section">
        <h3>Resident Risk Breakdown by Unit</h3>
        <ResponsiveContainer width="100%" height={260}>
          <BarChart
            data={unitSummary}
            margin={{ top: 20, right: 40, left: 20, bottom: 20 }}
            barCategoryGap="20%"
            barGap={6}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="unit" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="total" name="Total Residents" fill="#1976d2" radius={[4, 4, 0, 0]} />
            <Bar dataKey="highAcuity" name="High Acuity" fill="#f44336" radius={[4, 4, 0, 0]} />
            <Bar dataKey="highFall" name="High Fall Risk" fill="#ffa000" radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* 📊 Top Charts */}
      <div className="chart-section-flex">
        <div className="chart-box">
          <h3>Top 10 High Acuity Residents</h3>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={topAcuityData} layout="vertical" margin={{ left: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 10]} />
              <YAxis dataKey="name" type="category" width={100} />
              <Tooltip />
              <Bar dataKey="score" fill="#f44336" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-box">
          <div className="dropdown-wrapper">
            <h3>Top 10 by</h3>
            <select
              value={selectedRiskType}
              onChange={(e) => setSelectedRiskType(e.target.value)}
            >
              {riskTypes.map((rt) => (
                <option key={rt.risk_type_code} value={rt.risk_type_code}>
                  {rt.risk_type_name}
                </option>
              ))}
            </select>
          </div>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={topSelectedRiskData} layout="vertical" margin={{ left: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 10]} />
              <YAxis dataKey="name" type="category" width={100} />
              <Tooltip />
              <Bar dataKey="score" fill="#1976d2" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* 🔍 Filter + Print */}
      <div className="filters">
        <input
          type="text"
          placeholder="Search resident by name..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button onClick={() => window.print()} className="print-button">🖨️ Print Preview</button>
      </div>

      {/* 📋 Resident Table */}
      <div className="resident-table-section">
        <table className="resident-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Acuity Score</th>
              {riskTypes.map((rt) => (
                <th key={rt.risk_type_code}>{rt.risk_type_name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredResidents.map((resident) => (
              <tr key={resident.resident_id}>
                <td>{resident.resident_name}</td>
                <td>{resident.ACUITY_SCORE ?? 0}</td>
                {riskTypes.map((rt) => (
                  <td
                    key={`${resident.resident_id}-${rt.risk_type_code}`}
                    className={resident[rt.risk_type_code] > 6 ? "critical-score" : ""}
                  >
                    {resident[rt.risk_type_code] ?? 0}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}