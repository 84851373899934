import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "./Overview.css";

export default function Overview() {
  // Data for US Senior Population Growth
  const seniorPopulationData = {
    labels: ["2000", "2005", "2010", "2015", "2020", "2025", "2030"],
    datasets: [
      {
        label: "Senior Population (millions)",
        data: [35, 40, 45, 50, 55, 65, 75],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.2)",
        fill: true,
      },
    ],
  };

  // Data for Caregiver Shortage Over Time
  const caregiverShortageData = {
    labels: ["2000", "2005", "2010", "2015", "2020", "2025", "2030"],
    datasets: [
      {
        label: "Caregiver to Senior Ratio",
        data: [7, 6, 5, 4, 3.5, 3, 2.5],
        borderColor: "red",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        fill: true,
      },
    ],
  };

  return (
    <div className="overview-container">
      <h2>Senior Care Industry Trend</h2>
      <div className="chart-container">
        <h3>US Senior Population Growth</h3>
        <Line data={seniorPopulationData} />
      </div>
      <div className="chart-container">
        <h3>Caregiver Shortage Over Time</h3>
        <Line data={caregiverShortageData} />
      </div>
    </div>
  );
}
